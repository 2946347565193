import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/expertPage",
    name: "expertPage",
    component: () => import("../views/expertPage.vue"),
  },
  {
    path: "/schoolActivity",
    name: "schoolActivity",
    component: () => import("../views/schoolActivity.vue"),
  },
  {
    path: "/contactPage",
    name: "contactPage",
    component: () => import("../views/contactPage.vue"),
  },
  {
    path: "/abountPage",
    name: "abountPage",
    component: () => import("../views/abountPage.vue"),
  },
  {
    path: "/activityDetail",
    name: "/activityDetail",
    component: () => import("../views/activityDetail.vue"),
  },
  {
    path: "/archivalInformation",
    name: "/archivalInformation",
    component: () => import("../views/archival-Information.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: './',
  routes,
});

export default router;
